import "./Header.css";
import logo from "../../assets/logo2.png";
import { CiMenuFries } from "react-icons/ci";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState(1);

  const location = useLocation();
  useEffect(() => {
    const path = location.pathname;
    switch (path) {
      case "/":
        setActiveId(1);
        break;
      case "/about":
        setActiveId(2);
        break;
      case "/driving-school":
        setActiveId(3);
        break;
      case "/galery":
        setActiveId(4);
        break;

      default:
        setActiveId(1);
        break;
    }
  }, [location]);

  const [active, setActive] = useState(false);
  const handleSidebar = () => {
    setActive(!active);
  };

  return (
    <div className="header">
      <img
        src={logo}
        alt="logo"
        className="header__logo"
        onClick={() => navigate("/")}
      />
      <ul className="header__nav">
        <li
          onClick={() => {
            navigate("/about");
            setActiveId(1);
          }}
          className={`header__nav__li${activeId == 2 ? " active" : ""}`}
        >
          About
        </li>
        <li
          onClick={() => {
            navigate("/driving-school");
            setActiveId(2);
          }}
          className={`header__nav__li${activeId == 3 ? " active" : ""}`}
        >
          Driving school
        </li>
        <li
          onClick={() => {
            navigate("/galery");
            setActiveId(3);
          }}
          className={`header__nav__li${activeId == 4 ? " active" : ""}`}
        >
          Galery
        </li>
      </ul>
      <span className="header__acc">
        <button>
          <a
            href="tel:+2348060909770"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <h3>Contact us</h3>
          </a>
        </button>
        <CiMenuFries className={`header__menu`} onClick={handleSidebar} />
      </span>

      {/* SIDE BAR ELEMENTS */}
      <div className={`sidebar${active ? "--active" : ""}`}>
        <span className="sidebar__header">
          <img
            src={logo}
            alt="logo"
            className="header__logo"
            onClick={() => navigate("/")}
          />
          <button onClick={handleSidebar}>Close</button>
        </span>
        <ul className="sidebar__nav">
          <li
            onClick={() => navigate("/about")}
            className={`sidebar__nav__li${activeId == 2 ? " active" : ""}`}
          >
            About
          </li>
          <li
            onClick={() => navigate("/driving-school")}
            className={`sidebar__nav__li${activeId == 3 ? " active" : ""}`}
          >
            Driving school
          </li>
          <li
            onClick={() => navigate("/galery")}
            className={`sidebar__nav__li${activeId == 4 ? " active" : ""}`}
          >
            Galery
          </li>
        </ul>
        <span className="sidebar__acc">
          <button>
            <a
              href="tel:+2348060909770"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <h3>Contact us</h3>
            </a>
          </button>
        </span>
      </div>
    </div>
  );
}

export default Header;
