import Hero from "../../assets/IMG-20240901-WA0003.jpg";
import Hero1 from "../../assets/IMG-20240901-WA0004.jpg";
import Hero2 from "../../assets/IMG-20240901-WA0005.jpg";
import Hero3 from "../../assets/IMG-20240901-WA0006.jpg";
import Hero4 from "../../assets/IMG-20240901-WA0007.jpg";
import Hero5 from "../../assets/IMG-20240901-WA0008.jpg";
import Hero6 from "../../assets/IMG-20240901-WA0009.jpg";
import Hero7 from "../../assets/IMG-20240901-WA0010.jpg";
import Hero8 from "../../assets/IMG-20240901-WA0011.jpg";
import Hero9 from "../../assets/IMG-20240901-WA0012.jpg";
import Hero10 from "../../assets/IMG-20240901-WA0045.jpg";
import Hero11 from "../../assets/IMG-20240901-WA0046.jpg";
import Hero12 from "../../assets/IMG-20240901-WA0047.jpg";
import Hero13 from "../../assets/IMG-20240901-WA0048.jpg";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import FullScreenImageViewer from "../../utils/imageViewer/FullScreenImageViewer";
import "./galery.css";

function Galery() {
  return (
    <>
      <Header />
      <div className="galery">
        <section className="about__section__one">
          <img src={Hero} className="" />
          <div className="overlay" />
          <div className="about__section__hero">
            <h1>Galery</h1>
          </div>
        </section>
        <section className="galery__section__one">
          <h1>Expert Auto Repair Services You Can Trust</h1>
          <p>
            At Chibufavour automobiles, we provide top-quality automotive repair
            and maintenance services for all types of vehicles. Explore our
            gallery to see our skilled technicians in action, working on a
            variety of repairs—from routine maintenance to complex diagnostics.
            Our state-of-the-art equipment and commitment to excellence ensure
            your vehicle is in safe hands, getting the care it deserves.
          </p>
          <div className="galery__section__two__galery__wrap">
            <FullScreenImageViewer imageUrl={Hero2} />
            <FullScreenImageViewer imageUrl={Hero3} />
            <FullScreenImageViewer imageUrl={Hero4} />
            <FullScreenImageViewer imageUrl={Hero5} />
            <FullScreenImageViewer imageUrl={Hero6} />
            <FullScreenImageViewer imageUrl={Hero7} />
            <FullScreenImageViewer imageUrl={Hero8} />
            <FullScreenImageViewer imageUrl={Hero9} />
            <FullScreenImageViewer imageUrl={Hero10} />
            <FullScreenImageViewer imageUrl={Hero11} />
            <FullScreenImageViewer imageUrl={Hero12} />
            <FullScreenImageViewer imageUrl={Hero13} />
          </div>
        </section>
      </div>
      <Footer />
    </>
  );
}

export default Galery;
