import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Ds from "./pages/ds/Ds";
import About from "./pages/about/About";
import Home from "./pages/home/Home";
import Galery from "./pages/galery/Galery";
import ScrollToTop from "./utils/ScrollToTop";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/galery" element={<Galery />} />
          <Route path="/driving-school" element={<Ds />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
