import React, { useState } from "react";
import "./fullScreenImageViewer.css";

const FullScreenImageViewer = ({ imageUrl }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const handleImageClick = () => {
    setIsFullScreen(true);
  };

  const closeFullScreen = () => {
    setIsFullScreen(false);
  };

  return (
    <div className="image__viewer">
      <img
        src={imageUrl}
        alt="Click to view full screen"
        onClick={handleImageClick}
        className="image__viewer__image"
      />

      {isFullScreen && (
        <div className="fullscreen-overlay" onClick={closeFullScreen}>
          <div className="fullscreen-image-wrapper">
            <img
              src={imageUrl}
              alt="Full screen view"
              className="fullscreen-image"
            />
          </div>
          <button className="close-button" onClick={closeFullScreen}>
            ✕
          </button>
        </div>
      )}
    </div>
  );
};

export default FullScreenImageViewer;
